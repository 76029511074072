var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showResetPasswordDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c("validation-observer", {
            ref: "resetPassswordForm",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var passed = ref.passed
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.resetPassword.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "elevation-0",
                            attrs: { dark: "", color: "#673AB7" }
                          },
                          [
                            _c("v-toolbar-title", [_vm._v("Reset Password")]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", dark: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "reset-password-dialog-close"
                                    )
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c("p", [
                              _vm._v("Enter and confirm your password")
                            ]),
                            _c("validation-provider", {
                              attrs: {
                                bails: false,
                                name: "Password",
                                rules: {
                                  hasAtLeastOneUpperChar: true,
                                  hasAtLeastOneLowerChar: true,
                                  hasAtLeastOneNumber: true,
                                  required: true,
                                  passwordMin: { length: 8 }
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      var valid = ref.valid
                                      var touched = ref.touched
                                      var failedRules = ref.failedRules
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "append-icon": _vm.showPassword
                                              ? _vm.mdiEyeOff
                                              : _vm.mdiEye,
                                            label: "Password *",
                                            "hide-details": "auto",
                                            success: valid,
                                            error: errors.length > 0,
                                            name: "password",
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password"
                                          },
                                          on: {
                                            "click:append": function($event) {
                                              _vm.showPassword = !_vm.showPassword
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "prepend",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: _vm.getIconColor(
                                                            valid,
                                                            touched
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiLock
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.password,
                                            callback: function($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password"
                                          }
                                        }),
                                        !touched
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "caption px-8 pt-2 font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    " Password must include: "
                                                  )
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.getValidMsgs(),
                                                function(msg) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: msg,
                                                      staticClass:
                                                        "caption px-8"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(msg) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ]
                                          : _vm._e(),
                                        touched
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "caption px-8 pt-2 font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    " Password must include: "
                                                  )
                                                ]
                                              ),
                                              _vm._l(errors, function(error) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: error,
                                                    staticClass:
                                                      "caption invalid-msgs px-8"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color: "#ff5252"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.mdiClose)
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " + _vm._s(error) + " "
                                                    )
                                                  ],
                                                  1
                                                )
                                              }),
                                              _vm._l(
                                                _vm.getValidMsgs(failedRules),
                                                function(msg) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: msg,
                                                      staticClass:
                                                        "caption valid-msgs px-8"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color:
                                                              "rgba(32, 150, 13, 0.76)"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiCheck
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " + _vm._s(msg) + " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            ]
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("validation-provider", {
                              attrs: {
                                bails: false,
                                name: "confirmPassword",
                                rules: {
                                  passwordsMatch: "@Password"
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      var valid = ref.valid
                                      var touched = ref.touched
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "append-icon": _vm.showConfirmPassword
                                              ? _vm.mdiEyeOff
                                              : _vm.mdiEye,
                                            label: "Confirm Password *",
                                            "hide-details": "auto",
                                            success: valid,
                                            error: errors.length > 0,
                                            name: "confirmPassword",
                                            type: _vm.showConfirmPassword
                                              ? "text"
                                              : "password"
                                          },
                                          on: {
                                            "click:append": function($event) {
                                              _vm.showConfirmPassword = !_vm.showConfirmPassword
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "prepend",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: _vm.getIconColor(
                                                            valid,
                                                            touched
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiLock
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.confirmPassword,
                                            callback: function($$v) {
                                              _vm.confirmPassword = $$v
                                            },
                                            expression: "confirmPassword"
                                          }
                                        }),
                                        touched
                                          ? [
                                              valid &&
                                              _vm.confirmPassword.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: _vm.msg,
                                                      staticClass:
                                                        "caption valid-msgs pt-2 px-8"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color:
                                                              "rgba(32, 150, 13, 0.76)"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiCheck
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " Passwords match "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._l(errors, function(
                                                    error
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: error,
                                                        staticClass:
                                                          "caption invalid-msgs pt-2 px-8"
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                              color: "#ff5252"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.mdiClose
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(error) +
                                                            " "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  })
                                            ]
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-end pt-n3 pb-3 pr-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "#673AB7",
                                  type: "submit",
                                  dark: passed,
                                  disabled: !passed
                                }
                              },
                              [_vm._v(" Reset Password ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }