<template>
  <v-dialog v-model="showInvalidArcGisUserDialog" width="500">
    <v-card class="m-2">
      <v-card-text>
        <div class="pt-5">
          ArcGIS Online user is not registered with UtiliSync.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          raised
          text
          color="#673AB7"
          @click="$emit('invalid-arcgis-user-dialog-close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InvalidArcGisUserDialog",
  props: {
    showInvalidArcGisUserDialog: Boolean,
  },
};
</script>
