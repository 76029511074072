var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showForgotPasswordDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        { attrs: { id: "forgotPassswordForm" } },
        [
          _c("validation-observer", { ref: "forgotPassswordForm" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.resetPassword.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "v-toolbar",
                  {
                    staticClass: "elevation-0",
                    attrs: { dark: "", color: "#3F51B5" }
                  },
                  [
                    _c("v-toolbar-title", [_vm._v("Forgot Password")]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", dark: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("forgot-password-dialog-close")
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c("validation-provider", {
                      attrs: { name: "Username", rules: "required" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            var valid = ref.valid
                            return [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": _vm.mdiAccount,
                                  label: "Username *",
                                  "hide-details": "auto",
                                  "error-messages": errors,
                                  success: valid,
                                  color: "rgba(32, 150, 13, 0.76)",
                                  name: "username"
                                },
                                model: {
                                  value: _vm.username,
                                  callback: function($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-end pb-3 pr-3" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "#3F51B5",
                          type: "button",
                          text: "",
                          id: "forgot-username"
                        },
                        on: { click: _vm.showChat }
                      },
                      [_vm._v(" Forgot Username ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "#3F51B5",
                          type: "submit",
                          dark: "",
                          id: "reset-password"
                        }
                      },
                      [_vm._v("Reset Password")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }