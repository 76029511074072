<template>
  <v-dialog :value="showResetPasswordDialog" max-width="500px" persistent>
    <v-card>
      <validation-observer ref="resetPassswordForm" v-slot="{ passed }">
        <form @submit.prevent="resetPassword">
          <v-toolbar dark color="#673AB7" class="elevation-0">
            <v-toolbar-title>Reset Password</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('reset-password-dialog-close')">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <p>Enter and confirm your password</p>
            <validation-provider
              :bails="false"
              v-slot="{ errors, valid, touched, failedRules }"
              name="Password"
              :rules="{
                hasAtLeastOneUpperChar: true,
                hasAtLeastOneLowerChar: true,
                hasAtLeastOneNumber: true,
                required: true,
                passwordMin: { length: 8 },
              }"
            >
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? mdiEyeOff : mdiEye"
                label="Password *"
                hide-details="auto"
                :success="valid"
                :error="errors.length > 0"
                name="password"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              >
                <template #prepend>
                  <v-icon :color="getIconColor(valid, touched)">
                    {{ mdiLock }}
                  </v-icon>
                </template>
              </v-text-field>

              <template v-if="!touched">
                <div class="caption px-8 pt-2 font-weight-bold">
                  Password must include:
                </div>
                <div
                  v-for="msg of getValidMsgs()"
                  :key="msg"
                  class="caption px-8"
                >
                  - {{ msg }}
                </div>
              </template>
              <template v-if="touched">
                <div class="caption px-8 pt-2 font-weight-bold">
                  Password must include:
                </div>
                <div
                  v-for="error of errors"
                  :key="error"
                  class="caption invalid-msgs px-8"
                >
                  <v-icon x-small color="#ff5252">{{ mdiClose }}</v-icon>
                  {{ error }}
                </div>
                <div
                  v-for="msg of getValidMsgs(failedRules)"
                  :key="msg"
                  class="caption valid-msgs px-8"
                >
                  <v-icon x-small color="rgba(32, 150, 13, 0.76)">
                    {{ mdiCheck }}
                  </v-icon>
                  {{ msg }}
                </div>
              </template>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid, touched }"
              :bails="false"
              name="confirmPassword"
              :rules="{
                passwordsMatch: '@Password',
              }"
            >
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? mdiEyeOff : mdiEye"
                label="Confirm Password *"
                hide-details="auto"
                :success="valid"
                :error="errors.length > 0"
                name="confirmPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
              >
                <template #prepend>
                  <v-icon :color="getIconColor(valid, touched)">
                    {{ mdiLock }}
                  </v-icon>
                </template>
              </v-text-field>

              <template v-if="touched">
                <div
                  v-if="valid && confirmPassword.length > 0"
                  :key="msg"
                  class="caption valid-msgs pt-2 px-8"
                >
                  <v-icon x-small color="rgba(32, 150, 13, 0.76)">
                    {{ mdiCheck }}
                  </v-icon>
                  Passwords match
                </div>
                <div
                  v-else
                  v-for="error of errors"
                  :key="error"
                  class="caption invalid-msgs pt-2 px-8"
                >
                  <v-icon x-small color="#ff5252">{{ mdiClose }}</v-icon>
                  {{ error }}
                </div>
              </template>
            </validation-provider>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pt-n3 pb-3 pr-3">
            <v-btn
              color="#673AB7"
              type="submit"
              :dark="passed"
              :disabled="!passed"
            >
              Reset Password
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiLock, mdiEye, mdiEyeOff, mdiCheck } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;
const RULES = [
  "hasAtLeastOneUpperChar",
  "hasAtLeastOneLowerChar",
  "hasAtLeastOneNumber",
  "passwordMin",
];

export default {
  name: "ResetPasswordDialog",
  props: {
    showResetPasswordDialog: Boolean,
  },
  data() {
    return {
      mdiClose,
      mdiLock,
      mdiEye,
      mdiEyeOff,
      mdiCheck,
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    getIconColor(valid, touched) {
      if (touched) {
        return valid ? "rgba(32, 150, 13, 0.76)" : "#ff5252";
      }
    },
    getValidMsgs(failedRules = {}) {
      const passedRules = RULES.filter(
        (r) => !Object.keys(failedRules).includes(r)
      );
      return passedRules.map((r) => {
        if (r === "hasAtLeastOneUpperChar") {
          return "Password contains at least one upper case letter";
        } else if (r === "hasAtLeastOneLowerChar") {
          return "Password contains at least one lower case letter";
        } else if (r === "hasAtLeastOneNumber") {
          return "Password contains at least one number";
        } else if (r === "passwordMin") {
          return "Password contains at least eight characters";
        }
      });
    },
    async resetPassword() {
      const success = await this.$refs.resetPassswordForm.validate();
      if (!success) {
        return;
      }
      const { password } = this;
      const { token } = this.$route.query;
      await axiosWithRegularAuth.post(`${APIURL}/users/reset_password`, {
        password,
        token,
      });
      this.$emit("password-reset");
    },
  },
};
</script>

<style scoped>
.valid-msgs {
  color: rgba(32, 150, 13, 0.76);
}

.invalid-msgs {
  color: #ff5252;
}
</style>
