<template>
  <v-app>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="400">
        <v-card class="m-2" id="signin-card">
          <validation-observer ref="form">
            <form @submit.prevent="submit">
              <v-toolbar dark color="#3F51B5" class="elevation-0">
                <v-toolbar-title>Sign In</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="reload()" text>
                  <v-icon size="18" class="mr-2">{{ mdiReload }}</v-icon>
                  Reload</v-btn
                >
              </v-toolbar>
              <v-card-text
                :style="{
                  height: $vuetify.breakpoint.xsOnly
                    ? `${contentHeight}px`
                    : 'auto',
                  'overflow-y': 'auto',
                }"
              >
                <v-row class="pb-0">
                  <v-col cols="12" class="d-flex justify-center">
                    <img
                      src="@/assets/UtiliSyncDocsLogo.svg"
                      height="115px"
                      width="auto"
                      id="logo"
                    />
                  </v-col>

                  <v-col cols="12" class="pb-2 mt-n5">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Username"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.username"
                        :prepend-icon="mdiAccount"
                        label="Username *"
                        hide-details="auto"
                        :error-messages="errors"
                        :success="valid"
                        color="#3F51B5"
                        name="username"
                        :disabled="loggingIn"
                        id="username"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="pt-2">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Password"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.password"
                        :prepend-icon="mdiLock"
                        label="Password *"
                        type="password"
                        hide-details="auto"
                        :error-messages="errors"
                        :success="valid"
                        color="#3F51B5"
                        name="password"
                        :disabled="loggingIn"
                        id="password"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row class="py-0 mb-n1">
                  <v-col cols="12">
                    <v-btn
                      type="submit"
                      elevation="0"
                      raised
                      width="100%"
                      color="#3F51B5"
                      depressed
                      :disabled="loggingIn"
                      dark
                      id="sign-in"
                    >
                      Sign In
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="pb-0 my-0">
                  <v-col cols="12" class="d-flex justify-end py-0 my-0">
                    <v-btn
                      text
                      color="#3F51B5"
                      class="pa-0 ma-0"
                      @click="showForgotPasswordDialog = true"
                      id="forgot-password"
                    >
                      Forgot Password?
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="strike">
                  <span>OR</span>
                </div>

                <v-row class="pb-0 mt-n1">
                  <v-col cols="6">
                    <v-btn
                      type="button"
                      elevation="0"
                      raised
                      width="100%"
                      color="#3F51B5"
                      @click="openArcGisSignIn"
                      outlined
                      id="arcgis-online"
                    >
                      <div>ArcGIS Online</div>
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      type="button"
                      elevation="0"
                      raised
                      width="100%"
                      color="#3F51B5"
                      @click="showPortalUrlDialog = true"
                      outlined
                      id="arcgis-enterprise"
                    >
                      <div>ArcGIS Enterprise</div>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="pt-0 mb-n2">
                  <v-col cols="12" class="justify-center gap d-flex">
                    Don't have an account yet?
                    <a
                      :href="
                        $router.resolve({
                          path: '/signup',
                        }).href
                      "
                      class="font-weight-regular"
                      id="free-trial"
                    >
                      Start a free trial today!
                    </a>
                  </v-col>
                </v-row>
              </v-card-text>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-dialog v-model="showLoginFailedDialog" width="500">
        <v-card class="m-2">
          <v-card-text>
            <div class="pt-5">
              {{ computedErrorMessage }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              raised
              text
              color="#3F51B5"
              @click="showLoginFailedDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ArcGisEnterpriseLoginDialog
        v-if="showPortalUrlDialog"
        :showPortalUrlDialog="showPortalUrlDialog"
        @portal-url-dialog-close="showPortalUrlDialog = false"
        @portal-login-success="onPortalLoginSuccess"
      />

      <InvalidArcGisUserDialog
        :showInvalidArcGisUserDialog="showInvalidArcGisUserDialog"
        @invalid-arcgis-user-dialog-close="showInvalidArcGisUserDialog = false"
      />

      <ForgotPasswordDialog
        :showForgotPasswordDialog="showForgotPasswordDialog"
        @forgot-password-dialog-close="showForgotPasswordDialog = false"
        @password-reset-email-sent="onPasswordResetEmailSent"
      />

      <ResetPasswordDialog
        :showResetPasswordDialog="showResetPasswordDialog"
        @reset-password-dialog-close="showResetPasswordDialog = false"
        @password-reset="onPasswordReset"
      />

      <v-snackbar
        v-model="showPasswordResetEmailSnackbar"
        class="link-snackbar"
      >
        <section class="d-flex align-center justify-space-between">
          <div style="white-space: nowrap">
            A link to reset your password has been sent to the email address
            associated with your account.
          </div>
          <v-btn
            class="pa-0 ma-0"
            text
            @click="showPasswordResetEmailSnackbar = false"
          >
            Close
          </v-btn>
        </section>
      </v-snackbar>

      <v-snackbar v-model="showPasswordResetSnackbar">
        <section class="d-flex justify-space-between align-center">
          <div>Your password has now been reset.</div>
          <v-btn
            class="pa-0 ma-0"
            text
            @click="showPasswordResetSnackbar = false"
          >
            Close
          </v-btn>
        </section>
      </v-snackbar>
    </v-row>
  </v-app>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { axiosWithRegularAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import { mapMutations } from "vuex";
import { mdiAccount, mdiLock, mdiClose, mdiReload } from "@mdi/js";
import ForgotPasswordDialog from "@/components/login/ForgotPasswordDialog";
import ResetPasswordDialog from "@/components/login/ResetPasswordDialog";
import getPopUpDimensions from "@/mixins/getPopUpDimensions";
import ArcGisEnterpriseLoginDialog from "@/components/app/ArcGisEnterpriseLoginDialog";
import InvalidArcGisUserDialog from "@/components/app/InvalidArcGisUserDialog";
import logRocketMixin from "@/mixins/logRocketMixin";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;
const REDIRECT_URL = process.env.VUE_APP_REDIRECT_URL;
const cancelTokenSource = axios.CancelToken.source();

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
    ForgotPasswordDialog,
    ResetPasswordDialog,
    ArcGisEnterpriseLoginDialog,
    InvalidArcGisUserDialog,
  },
  mixins: [logRocketMixin],
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      dialog: true,
      loginWindow: undefined,
      portalLoginWindow: undefined,
      portalUrl: "https://",
      showPortalUrlDialog: false,
      showLoginFailedDialog: false,
      loggingIn: false,
      portalSignInClientId: "",
      showInvalidArcGisUserDialog: false,
      mdiAccount,
      mdiLock,
      mdiClose,
      mdiReload,
      showForgotPasswordDialog: false,
      showPasswordResetEmailSnackbar: false,
      showResetPasswordDialog: false,
      showPasswordResetSnackbar: false,
      errorMessage: undefined,
    };
  },
  mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    const { token } = this.$route.query;
    if (token) {
      this.showResetPasswordDialog = true;
    }
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
  async beforeMount() {
    const portalUrl = localStorage.getItem("portal-url");
    const layerVisibilitySettings = localStorage.getItem(
      "layer-visibility-settings"
    );
    localStorage.clear();
    localStorage.setItem("portal-url", portalUrl);
    localStorage.setItem("layer-visibility-settings", layerVisibilitySettings);
    await db.delete();
    await db.open();
    await db.mapServices.clear();
    await db.users.clear();
    await db.formDefinitions.clear();
    await db.maps.clear();
    await db.userDataFields.clear();
    await db.userDataValues.clear();
    await db.gisDataFields.clear();
    await db.gisDataPoints.clear();
    await db.gisDataValues.clear();
    await db.markupSymbols.clear();
    window.addEventListener("message", async ({ data }) => {
      this.portalLoginWindow?.close?.();
      this.loginWindow?.close?.();

      const { access_token: accessToken, expires_in: expiresIn } = data;
      if (!accessToken) {
        return;
      }
      const expirationDate = Date.now() + expiresIn * 1000;
      localStorage.setItem("esri_token", accessToken);
      localStorage.setItem("esri_token_expiration", expirationDate);
      try {
        const resData = await axiosWithRegularAuth.post(
          `${APIURL}/auth/esri_login`,
          {
            access_token: accessToken,
            portal_url: this.loginWindow
              ? "https://www.arcgis.com"
              : localStorage.getItem("portal-url"),
          }
        );
        const {
          data: {
            results: { refreshToken, esriUsername },
          },
        } = resData;
        localStorage.setItem("refresh_token", refreshToken);
        localStorage.setItem("esri_user", esriUsername);
        if (localStorage.getItem("token")) {
          return;
        }
        const refreshTokenData = await axiosWithRegularAuth.post(
          `${APIURL}/auth/refresh_token`,
          {
            refresh_token: localStorage.getItem("refresh_token"),
          }
        );
        const {
          data: {
            results: { token },
          },
        } = refreshTokenData;
        localStorage.setItem(
          "auth",
          JSON.stringify(refreshTokenData.data.results)
        );
        cancelTokenSource.cancel();
        localStorage.setItem("token", token);
        this.initLogRocket();

        const path = this.$route.query.redirectUrl || "/map";
        this.$router.push(path);
      } catch (error) {
        if (error?.response?.status === 403) {
          this.showInvalidArcGisUserDialog = true;
        }
      }
    });
  },
  computed: {
    computedErrorMessage() {
      return this.errorMessage
        ? this.errorMessage
        : "There was an error with the username or password submitted. Please try again.";
    },
  },
  methods: {
    reload() {
      location.reload();
    },
    onPortalLoginSuccess() {
      this.showPortalUrlDialog = false;
      const path = this.$route.query.redirectUrl || "/map";
      this.$router.push(path);
    },
    onPasswordReset() {
      this.showResetPasswordDialog = false;
      this.showPasswordResetSnackbar = true;
    },
    onPasswordResetEmailSent() {
      this.showForgotPasswordDialog = false;
      this.showPasswordResetEmailSnackbar = true;
    },
    openArcGisSignIn() {
      this.portalLoginWindow = undefined;
      const { popupWinWidth, popupWinHeight, left, top } = getPopUpDimensions();
      this.loginWindow = window.open(
        `https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=h3JBZPGpA1fkpkRz&response_type=token&redirect_uri=${REDIRECT_URL}&expiration=20160`,
        "login",
        `menubar=0,width=${popupWinWidth},height=${popupWinHeight},left=${left},top=${top}`
      );
      this.loginWindow.focus();
    },
    async submit() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      this.loggingIn = true;
      try {
        const data = await axiosWithRegularAuth.post(
          `${APIURL}/auth/get_token`,
          this.user
        );
        const {
          data: {
            results: { refresh_token: refreshToken, token },
          },
        } = data;
        cancelTokenSource.cancel();
        const layerVisibilitySettings = localStorage.getItem(
          "layer-visibility-settings"
        );
        localStorage.clear();
        await db.mapServices.clear();
        await db.users.clear();
        await db.formDefinitions.clear();
        await db.maps.clear();
        await db.userDataFields.clear();
        await db.userDataValues.clear();
        await db.gisDataFields.clear();
        await db.gisDataPoints.clear();
        await db.gisDataValues.clear();
        await db.markupSymbols.clear();
        localStorage.setItem(
          "layer-visibility-settings",
          layerVisibilitySettings
        );
        localStorage.setItem("auth", JSON.stringify(data.data.results));
        localStorage.setItem("refresh_token", refreshToken);
        localStorage.setItem("token", token);
        const { last_map_opened: lastMapOpenedId } = data.data.results;
        this.setSelectedMapId(lastMapOpenedId);
        localStorage.setItem("last-map-opened-id", lastMapOpenedId);
        this.initLogRocket();

        const path = this.$route.query.redirectUrl || "/map";
        this.$router.push(path);
      } catch (error) {
        this.errorMessage = error?.response?.data?.error;
        this.showLoginFailedDialog = true;
      } finally {
        this.loggingIn = false;
      }
    },
    ...mapMutations(["setSelectedMapId"]),
  },
  watch: {
    "$route.query.username": {
      immediate: true,
      deep: true,
      async handler() {
        if (this.$route.query.username) {
          this.user.username = decodeURIComponent(this.$route.query.username);
        }
      },
    },
  },
};
</script>

<style scoped>
.link-snackbar >>> .v-snack__wrapper {
  max-width: none;
}
</style>

<style>
.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: black;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}
</style>

<style scoped>
#logo {
  width: 70%;
}

.v-btn div {
  white-space: normal;
  word-wrap: break-word;
  width: 100px;
}

.gap {
  gap: 5px;
}
</style>
